<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t('COMPARATIVE_ANALYSIS.AVERAGE_REPORT_BY_DAYS') }}:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold mr-2"
              >A: {{ rangeA.start | formatDate }} -
              {{ rangeA.end | formatDate }}</span
            ></span
          >
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >B: {{ rangeB.start | formatDate }} -
              {{ rangeB.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
        <div class="row">
          <div class="col"></div>
          <div class="col">
            {{ this.mondayA }}
            {{ this.mondayB }}
          </div>
          <div class="col">
            {{ this.tuesdayA }}
            {{ this.tuesdayB }}
          </div>
          <div class="col">
            {{ this.wednesdayA }}
            {{ this.wednesdayB }}
          </div>
          <div class="col">
            {{ this.thursdayA }}
            {{ this.thursdayB }}
          </div>
          <div class="col">
            {{ this.fridayA }}
            {{ this.fridayB }}
          </div>
          <div class="col">
            {{ this.saturdayA }}
            {{ this.saturdayB }}
          </div>
          <div class="col">
            {{ this.sundayA }}
            {{ this.sundayB }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import parse from 'date-fns/parse'
import getDay from 'date-fns/getDay'
import getWeek from 'date-fns/getWeek'
import { dateFilter } from 'vue-date-fns'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import isMonday from 'date-fns/isMonday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'
import { mapGetters } from 'vuex'

export default {
  name: 'WeekDaysTrafficDual',
  filters: {
    date: dateFilter,
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    trafficA: {
      type: Array,
    },
    trafficB: {
      type: Array,
    },
    rangeA: {
      type: Object,
    },
    rangeB: {
      type: Object,
    },
  },
  data() {
    return {
      transactionsA: [],
      transactionsB: [],

      coinTransactionsA: [],
      coinTransactionsB: [],

      smsTransactionsA: [],
      smsTransactionsB: [],

      loyaltyTransactionsA: [],
      loyaltyTransactionsB: [],

      days: [],

      mondayA: 0,
      tuesdayA: 0,
      wednesdayA: 0,
      thursdayA: 0,
      fridayA: 0,
      saturdayA: 0,
      sundayA: 0,

      mondayB: 0,
      tuesdayB: 0,
      wednesdayB: 0,
      thursdayB: 0,
      fridayB: 0,
      saturdayB: 0,
      sundayB: 0,

      day1A: {
        coin: { name: 'day1', total: 0 },
        sms: { name: 'day1', total: 0 },
        loyalty: { name: 'day1', total: 0 },
      },
      day2A: {
        coin: { name: 'day2', total: 0 },
        sms: { name: 'day2', total: 0 },
        loyalty: { name: 'day2', total: 0 },
      },
      day3A: {
        coin: { name: 'day3', total: 0 },
        sms: { name: 'day3', total: 0 },
        loyalty: { name: 'day3', total: 0 },
      },
      day4A: {
        coin: { name: 'day4', total: 0 },
        sms: { name: 'day4', total: 0 },
        loyalty: { name: 'day4', total: 0 },
      },
      day5A: {
        coin: { name: 'day5', total: 0 },
        sms: { name: 'day5', total: 0 },
        loyalty: { name: 'day5', total: 0 },
      },
      day6A: {
        coin: { name: 'day6', total: 0 },
        sms: { name: 'day6', total: 0 },
        loyalty: { name: 'day6', total: 0 },
      },
      day0A: {
        coin: { name: 'day0', total: 0 },
        sms: { name: 'day0', total: 0 },
        loyalty: { name: 'day0', total: 0 },
      },

      day1B: {
        coin: { name: 'day1', total: 0 },
        sms: { name: 'day1', total: 0 },
        loyalty: { name: 'day1', total: 0 },
      },
      day2B: {
        coin: { name: 'day2', total: 0 },
        sms: { name: 'day2', total: 0 },
        loyalty: { name: 'day2', total: 0 },
      },
      day3B: {
        coin: { name: 'day3', total: 0 },
        sms: { name: 'day3', total: 0 },
        loyalty: { name: 'day3', total: 0 },
      },
      day4B: {
        coin: { name: 'day4', total: 0 },
        sms: { name: 'day4', total: 0 },
        loyalty: { name: 'day4', total: 0 },
      },
      day5B: {
        coin: { name: 'day5', total: 0 },
        sms: { name: 'day5', total: 0 },
        loyalty: { name: 'day5', total: 0 },
      },
      day6B: {
        coin: { name: 'day6', total: 0 },
        sms: { name: 'day6', total: 0 },
        loyalty: { name: 'day6', total: 0 },
      },
      day0B: {
        coin: { name: 'day0', total: 0 },
        sms: { name: 'day0', total: 0 },
        loyalty: { name: 'day0', total: 0 },
      },

      options: {
        colors: [
          '#269ffb',
          '#269ffb',
          '#00e396',
          '#00e396',
          '#febb3b',
          '#febb3b',
        ],
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: false,
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          labels: {
            show: true,
            formatter(val, seriesName) {
              if (seriesName === 'Monday') return `Monday`
              if (seriesName === 'Tuesday') return `Tuesday`
              if (seriesName === 'Wednesday') return `Wednesday`
              if (seriesName === 'Thursday') return `Thursday`
              if (seriesName === 'Friday') return `Friday`
              if (seriesName === 'Saturday') return `Saturday`
              if (seriesName === 'Sunday') return `Sunday`
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin A',
          data: [],
        },
        {
          name: 'PayCoin B',
          data: [],
        },
        {
          name: 'PaySMS A',
          data: [],
        },
        {
          name: 'PaySMS B',
          data: [],
        },
        {
          name: 'PayLoyalty A',
          data: [],
        },
        {
          name: 'PayLoyalty B',
          data: [],
        },
      ],

      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    // A
    this.trafficA.forEach((transaction) => {
      this.transactionsA.push({
        day: parse(
          transaction.createdAt.split(' @')[0],
          'dd.MM.yyyy',
          new Date()
        ),
        week: getWeek(
          parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactionsA = this.transactionsA.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )
    this.smsTransactionsA = this.transactionsA.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )
    this.loyaltyTransactionsA = this.transactionsA.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    this.coinTransactionsA.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) {
        this.day1A.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 2) {
        this.day2A.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 3) {
        this.day3A.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 4) {
        this.day4A.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 5) {
        this.day5A.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 6) {
        this.day6A.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 0) {
        this.day0A.coin.total += parseFloat(transaction.amount)
      }
    })

    this.smsTransactionsA.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) {
        this.day1A.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 2) {
        this.day2A.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 3) {
        this.day3A.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 4) {
        this.day4A.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 5) {
        this.day5A.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 6) {
        this.day6A.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 0) {
        this.day0A.sms.total += parseFloat(transaction.amount)
      }
    })

    this.loyaltyTransactionsA.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) {
        this.day1A.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 2) {
        this.day2A.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 3) {
        this.day3A.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 4) {
        this.day4A.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 5) {
        this.day5A.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 6) {
        this.day6A.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 0) {
        this.day0A.loyalty.total += parseFloat(transaction.amount)
      }
    })

    this.mondayA = 0
    this.tuesdayA = 0
    this.wednesdayA = 0
    this.thursdayA = 0
    this.fridayA = 0
    this.saturdayA = 0
    this.sundayA = 0

    eachDayOfInterval(this.rangeA).forEach((day) => {
      if (isMonday(day)) this.mondayA++
      if (isTuesday(day)) this.tuesdayA++
      if (isWednesday(day)) this.wednesdayA++
      if (isThursday(day)) this.thursdayA++
      if (isFriday(day)) this.fridayA++
      if (isSaturday(day)) this.saturdayA++
      if (isSunday(day)) this.sundayA++
    })

    this.series[0].data.push(this.day1A.coin.total / this.mondayA) // pon
    this.series[0].data.push(this.day2A.coin.total / this.tuesdayA)
    this.series[0].data.push(this.day3A.coin.total / this.wednesdayA)
    this.series[0].data.push(this.day4A.coin.total / this.thursdayA)
    this.series[0].data.push(this.day5A.coin.total / this.fridayA)
    this.series[0].data.push(this.day6A.coin.total / this.saturdayA)
    this.series[0].data.push(this.day0A.coin.total / this.sundayA) // ned

    this.series[2].data.push(this.day1A.sms.total / this.mondayA) // pon
    this.series[2].data.push(this.day2A.sms.total / this.tuesdayA)
    this.series[2].data.push(this.day3A.sms.total / this.wednesdayA)
    this.series[2].data.push(this.day4A.sms.total / this.thursdayA)
    this.series[2].data.push(this.day5A.sms.total / this.fridayA)
    this.series[2].data.push(this.day6A.sms.total / this.saturdayA)
    this.series[2].data.push(this.day0A.sms.total / this.sundayA) // ned

    this.series[4].data.push(this.day1A.loyalty.total / this.mondayA) // pon
    this.series[4].data.push(this.day2A.loyalty.total / this.tuesdayA)
    this.series[4].data.push(this.day3A.loyalty.total / this.wednesdayA)
    this.series[4].data.push(this.day4A.loyalty.total / this.thursdayA)
    this.series[4].data.push(this.day5A.loyalty.total / this.fridayA)
    this.series[4].data.push(this.day6A.loyalty.total / this.saturdayA)
    this.series[4].data.push(this.day0A.loyalty.total / this.sundayA) // ned

    // B
    this.trafficB.forEach((transaction) => {
      this.transactionsB.push({
        day: parse(
          transaction.createdAt.split(' @')[0],
          'dd.MM.yyyy',
          new Date()
        ),
        week: getWeek(
          parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactionsB = this.transactionsB.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )
    this.smsTransactionsB = this.transactionsB.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )
    this.loyaltyTransactionsB = this.transactionsB.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    this.coinTransactionsB.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) {
        this.day1B.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 2) {
        this.day2B.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 3) {
        this.day3B.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 4) {
        this.day4B.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 5) {
        this.day5B.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 6) {
        this.day6B.coin.total += parseFloat(transaction.amount)
      }
      if (thisDay === 0) {
        this.day0B.coin.total += parseFloat(transaction.amount)
      }
    })

    this.smsTransactionsB.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) {
        this.day1B.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 2) {
        this.day2B.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 3) {
        this.day3B.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 4) {
        this.day4B.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 5) {
        this.day5B.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 6) {
        this.day6B.sms.total += parseFloat(transaction.amount)
      }
      if (thisDay === 0) {
        this.day0B.sms.total += parseFloat(transaction.amount)
      }
    })

    this.loyaltyTransactionsB.forEach((transaction) => {
      const thisDay = getDay(new Date(transaction.day))

      if (thisDay === 1) {
        this.day1B.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 2) {
        this.day2B.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 3) {
        this.day3B.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 4) {
        this.day4B.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 5) {
        this.day5B.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 6) {
        this.day6B.loyalty.total += parseFloat(transaction.amount)
      }
      if (thisDay === 0) {
        this.day0B.loyalty.total += parseFloat(transaction.amount)
      }
    })

    this.mondayB = 0
    this.tuesdayB = 0
    this.wednesdayB = 0
    this.thursdayB = 0
    this.fridayB = 0
    this.saturdayB = 0
    this.sundayB = 0

    eachDayOfInterval(this.rangeB).forEach((day) => {
      if (isMonday(day)) this.mondayB++
      if (isTuesday(day)) this.tuesdayB++
      if (isWednesday(day)) this.wednesdayB++
      if (isThursday(day)) this.thursdayB++
      if (isFriday(day)) this.fridayB++
      if (isSaturday(day)) this.saturdayB++
      if (isSunday(day)) this.sundayB++
    })

    this.series[1].data.push(this.day1B.coin.total / this.mondayB) // pon
    this.series[1].data.push(this.day2B.coin.total / this.tuesdayB)
    this.series[1].data.push(this.day3B.coin.total / this.wednesdayB)
    this.series[1].data.push(this.day4B.coin.total / this.thursdayB)
    this.series[1].data.push(this.day5B.coin.total / this.fridayB)
    this.series[1].data.push(this.day6B.coin.total / this.saturdayB)
    this.series[1].data.push(this.day0B.coin.total / this.sundayB) // ned

    this.series[3].data.push(this.day1B.sms.total / this.mondayB) // pon
    this.series[3].data.push(this.day2B.sms.total / this.tuesdayB)
    this.series[3].data.push(this.day3B.sms.total / this.wednesdayB)
    this.series[3].data.push(this.day4B.sms.total / this.thursdayB)
    this.series[3].data.push(this.day5B.sms.total / this.fridayB)
    this.series[3].data.push(this.day6B.sms.total / this.saturdayB)
    this.series[3].data.push(this.day0B.sms.total / this.sundayB) // ned

    this.series[5].data.push(this.day1B.loyalty.total / this.mondayB) // pon
    this.series[5].data.push(this.day2B.loyalty.total / this.tuesdayB)
    this.series[5].data.push(this.day3B.loyalty.total / this.wednesdayB)
    this.series[5].data.push(this.day4B.loyalty.total / this.thursdayB)
    this.series[5].data.push(this.day5B.loyalty.total / this.fridayB)
    this.series[5].data.push(this.day6B.loyalty.total / this.saturdayB)
    this.series[5].data.push(this.day0B.loyalty.total / this.sundayB) // ned

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
}
</script>
