<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          ABTraffic:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold mr-2"
              >A: {{ rangeA.start | date('dd.MM.yyyy') }} -
              {{ rangeA.end | date('dd.MM.yyyy') }}</span
            ></span
          >
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >B: {{ rangeB.start | date('dd.MM.yyyy') }} -
              {{ rangeB.end | date('dd.MM.yyyy') }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-5 text-center pl-15">
            <div>
              {{ rangeA.start | date('dd.MM.yyyy') }}
            </div>
            <div>
              {{ rangeA.end | date('dd.MM.yyyy') }}
            </div>
            <div>
              <span class="font-size-lg font-weight-bolder text-dark">
                {{
                  parseFloat(
                    sectionA.coin.total +
                      sectionA.sms.total +
                      sectionA.loyalty.total
                  ) | currencyFormat
                }}
              </span>
              <span class="font-size-lg font-weight-bolder text-dark">€</span>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-5 text-center pr-30">
            <div>
              {{ rangeB.start | date('dd.MM.yyyy') }}
            </div>
            <div>
              {{ rangeB.end | date('dd.MM.yyyy') }}
            </div>
            <div>
              <span class="font-size-lg font-weight-bolder text-dark">
                {{
                  parseFloat(
                    sectionB.coin.total +
                      sectionB.sms.total +
                      sectionB.loyalty.total
                  ) | currencyFormat
                }}
              </span>
              <span class="font-size-lg font-weight-bolder text-dark">€</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer py-6">
        <div class="d-flex justify-content-between">
          <span class="font-size-lg font-weight-bolder text-dark"
            ><span
              @click="scrollToASection"
              class="cursor-pointer text-hover-primary mr-2"
              ><i class="flaticon2-arrow-up icon-1x"></i></span
            >{{ $t('GENERAL.TOTAL') }} A: </span
          ><span class="font-size-lg font-weight-bolder text-dark">{{
            parseFloat(
              sectionA.coin.total + sectionA.sms.total + sectionA.loyalty.total
            ) | currencyFormat
          }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="font-size-lg font-weight-bolder text-dark"
            ><span
              @click="scrollToBSection"
              class="cursor-pointer text-hover-primary mr-2"
              ><i class="flaticon2-arrow-up icon-1x"></i></span
            >{{ $t('GENERAL.TOTAL') }} B: </span
          ><span class="font-size-lg font-weight-bolder text-dark">{{
            parseFloat(
              sectionB.coin.total + sectionB.sms.total + sectionB.loyalty.total
            ) | currencyFormat
          }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="font-size-lg font-weight-bolder text-dark">
            <span class="pl-6"
              >{{ $t('GENERAL.TOTAL') }} A - {{ $t('GENERAL.TOTAL') }} B:
            </span>
          </span>
          <span class="font-size-lg font-weight-bolder text-dark">{{
            (parseFloat(
              sectionA.coin.total + sectionA.sms.total + sectionA.loyalty.total
            ) -
              parseFloat(
                sectionB.coin.total +
                  sectionB.sms.total +
                  sectionB.loyalty.total
              ))
              | currencyFormat
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import parse from 'date-fns/parse'
import getWeek from 'date-fns/getWeek'
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'ABTraffic',
  filters: {
    date: dateFilter,
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    trafficA: {
      type: Array,
    },
    trafficB: {
      type: Array,
    },
    rangeA: {
      type: Object,
    },
    rangeB: {
      type: Object,
    },
  },
  data() {
    return {
      transactionsA: [],
      transactionsB: [],
      coinTransactionsA: [],
      coinTransactionsB: [],
      sectionA: {
        coin: { name: 'A Section', total: 0 },
        sms: { name: 'A Section', total: 0 },
        loyalty: { name: 'A Section', total: 0 },
      },
      sectionB: {
        coin: { name: 'B Section', total: 0 },
        sms: { name: 'B Section', total: 0 },
        loyalty: { name: 'B Section', total: 0 },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: false,
            dataLabels: {
              orientation: 'horizontal',
              position: 'center',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: ['A Section', 'B Section'],
          labels: {
            show: true,
            formatter(val) {
              return val
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'Pay Coin',
          data: [],
        },
        {
          name: 'Pay SMS',
          data: [],
        },
        {
          name: 'Pay Loyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    // A
    this.trafficA.forEach((transaction) => {
      this.transactionsA.push({
        day: parse(
          transaction.createdAt.split(' @')[0],
          'dd.MM.yyyy',
          new Date()
        ),
        week: getWeek(
          parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactionsA = this.transactionsA.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )

    this.smsTransactionsA = this.transactionsA.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )

    this.loyaltyTransactionsA = this.transactionsA.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    this.coinTransactionsA.forEach((transaction) => {
      this.sectionA.coin.total += parseFloat(transaction.amount)
    })

    this.smsTransactionsA.forEach((transaction) => {
      this.sectionA.sms.total += parseFloat(transaction.amount)
    })

    this.loyaltyTransactionsA.forEach((transaction) => {
      this.sectionA.loyalty.total += parseFloat(transaction.amount)
    })

    // B
    this.trafficB.forEach((transaction) => {
      this.transactionsB.push({
        day: parse(
          transaction.createdAt.split(' @')[0],
          'dd.MM.yyyy',
          new Date()
        ),
        week: getWeek(
          parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.coinTransactionsB = this.transactionsB.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )

    this.smsTransactionsB = this.transactionsB.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )

    this.loyaltyTransactionsB = this.transactionsB.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    this.coinTransactionsB.forEach((transaction) => {
      this.sectionB.coin.total += parseFloat(transaction.amount)
    })

    this.smsTransactionsB.forEach((transaction) => {
      this.sectionB.sms.total += parseFloat(transaction.amount)
    })

    this.loyaltyTransactionsB.forEach((transaction) => {
      this.sectionB.loyalty.total += parseFloat(transaction.amount)
    })

    this.series[0].data.push(this.sectionA.coin.total)
    this.series[1].data.push(this.sectionA.sms.total)
    this.series[2].data.push(this.sectionA.loyalty.total)
    this.series[0].data.push(this.sectionB.coin.total)
    this.series[1].data.push(this.sectionB.sms.total)
    this.series[2].data.push(this.sectionB.loyalty.total)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    scrollToASection() {
      document.getElementById('locationSectionsA').scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    },
    scrollToBSection() {
      document.getElementById('locationSectionsB').scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    },
  },
}
</script>
